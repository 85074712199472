import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { change } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import { formatString } from 'modules/common/utils';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import { loadTemplate, setReportPlatforms } from '../../actions';

import local from './local.module.scss';

interface TemplateItemProps {
    id: string;
    name: string;
}

const { REACT_APP_THUMBNAILS_PATH } = process.env;
const DEFAULT_THUMBNAIL_URL = `${REACT_APP_THUMBNAILS_PATH}/default.png`;
const THUMBNAIL_URL_PATTERN = `${REACT_APP_THUMBNAILS_PATH}/{0}.png`;

const TemplateItem: FunctionComponent<TemplateItemProps> = ({ id, name }) => {
    const dispatch = useAppDispatch();
    const selectedTemplateId = useReduxFormValue('reportForm', 'templateId');
    const isSelected = id === selectedTemplateId;

    return (
        <div
            className={classnames(local.templateContainer, { [local.selected]: isSelected }, 'container-column')}
            onClick={() => {
                if (!isSelected) {
                    dispatch(change('reportForm', 'templateId', id));
                    dispatch(change('reportForm', 'platforms', undefined));
                    dispatch(setReportPlatforms([]));
                    dispatch(loadTemplate(id));
                }
            }}
        >
            <div className={classnames(local.templateTitleRow, 'container-row')}>
                <TextWithTooltip>{name}</TextWithTooltip>
                <FontAwesomeIcon className={local.templateIcon} icon={isSelected ? faCircleCheck : faCircle} />
            </div>
            <img
                className={local.templateThumbnail}
                src={formatString(THUMBNAIL_URL_PATTERN, id)}
                onError={(event) => {
                    (event.target as HTMLImageElement).src = DEFAULT_THUMBNAIL_URL;
                }}
                alt='Thumbnail image not found'
            />
        </div>
    );
};

export default TemplateItem;
